<template>
  <div class="row mx-2 my-0 gl-block">
    <div
      class="
        col-12 col-md-10 col-lg-8
        offset-md-1 offset-lg-2
        card
        theme-card
        border-0
        p-5
      "
    >
      <alert class="my-4" v-if="stripeConfig.isLoading" />
      <div class="d-flex flex-column" v-if="!stripeConfig.isLoading">
        <h1 class="mb-4">Stripe configuration</h1>
        <select-control
          type="horizontal"
          :options="yesNoOptions"
          required
          labelClass="col-12 col-md-3 col-lg-2"
          v-model="stripeConfig.data.is_enabled"
          >Enable</select-control
        >
        <input-control
          type="horizontal"
          v-model="stripeConfig.data.public_key"
          required
          labelClass="col-md-3 col-lg-2"
          >Public Key</input-control
        >
        <input-control
          type="horizontal"
          v-model="stripeConfig.data.webhook_signing_secret"
          required
          labelClass="col-md-3 col-lg-2"
          >Webhook Signing Secret</input-control
        >
        <div class="input-container">
          <button @click="toggleEditing" class="btn btn-link-blue edit-button">
            {{ isEditing ? "Disable" : "Edit" }}
          </button>
          <input-control
            type="horizontal"
            :value="isEditing ? tempKey : maskedValue"
            @input="updateValue"
            :disabled="!isEditing"
            required
            labelClass="col-md-3 col-lg-2"
            >Private Key</input-control
          >
        </div>
        <select-control
          type="horizontal"
          :options="currencyOptions"
          required
          labelClass="col-12 col-md-3 col-lg-2"
          v-model="stripeConfig.data.currency"
          >Currency</select-control
        >
        <div class="text-center">
          <button
            :saving="stripeConfig.isSaving"
            class="btn btn-black"
            @click.prevent="saveChanges"
          >
            <span
              class="spinner-border spinner-border-sm"
              v-if="stripeConfig.isSaving"
            ></span>
            <span v-else>Save Configuration</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "stripe.config",
  computed: {
    ...mapState({
      patients: (state) => state.patients,
      stripeConfig: (state) => state.stripeConfig,
      patient: (state) => state.patients.patient,
      user: (state) => state.auth.user,
    }),
    maskedValue() {
      if (this.stripeConfig.data.private_key) {
        return this.stripeConfig.data.private_key + "*".repeat(37);
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.getStripeConfig();
  },
  data: () => ({
    yesNoOptions: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    currencyOptions: [
      { label: "USD", value: "usd" },
      { label: "CAD", value: "cad" },
    ],
    isEditing: false,
    tempKey: "",
  }),
  methods: {
    ...mapActions({
      getStripeConfig: "stripeConfig/getStripeConfig",
      saveStripeConfig: "stripeConfig/saveStripeConfig",
    }),
    toggleEditing() {
      this.isEditing = !this.isEditing;
      if (this.isEditing) {
        this.tempKey = "";
      }
    },
    updateValue(value) {
      this.tempKey = value;
    },
    saveChanges: function () {
      if (!this.isEditing) {
        delete this.stripeConfig.data.private_key;
      } else {
        this.stripeConfig.data.private_key = this.tempKey;
      }
      this.saveStripeConfig(this?.stripeConfig?.data);
    },
  },
};
</script>
<style>
.input-container {
  position: relative;
  display: inline-block;
}

.edit-button {
  position: absolute;
  top: 0;
  right: 5px;
  z-index: 10;
}
</style>
